import storeNotice from './store-notice.js';

( function( $ ) {

  $( document ).ready( function() {

    let noticeClass = new storeNotice();
    noticeClass.init();

  });

})( jQuery );