export default class storeNotice {

    constructor() {

      this.settings = {
        body: document.getElementsByTagName( 'body' )[0],
        notice: document.getElementsByClassName( 'woocommerce-store-notice' )[0]
      }

    }

    init() {
      if ( null !== this.settings.notice ) {
        this.bindUIActions();
      }

    }

    bindUIActions() {

      let s = this.settings;

        let noticeID = s.notice.dataset.noticeId;
        let cookieName = 'store_notice' + noticeID;

        // Check the value of that cookie and show/hide the notice accordingly
        if ( 'hidden' === Cookies.get( cookieName ) ) {
            s.body.classList.add( "store-notice--inactive" );
        } else {
            s.body.classList.remove( "store-notice--inactive" );
        }

    }

}